export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

export const AWS_API = {
  poolId: 'us-east-1_AOfOTXLvD',
  appClientId: '3eau2osduslvb7vks3vsh9t7b0',
};

export const JWT_API = {
  secret: 'SECRET-KEY',
  timeout: '1 days',
};

export const AUTH0_API = {
  client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
  domain: 'dev-w0-vxep3.us.auth0.com',
};

// ==============================|| THEME CONFIG  ||============================== //

const csvToArray = (data, delimiter = ',', omitFirstRow = false) =>
  data.slice(omitFirstRow ? data.indexOf('\n') + 1 : 0).split(delimiter);

/* eslint-disable no-underscore-dangle */
const config = {
  defaultPath: '/links',
  fontFamily: `'Cabin', 'Poppins', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr',
  environmentList: ['dev', 'stage', 'prod'],
  environment: window._env_.REACT_APP_ENVIRONMENT || 'local',
  muiKey: window._env_.REACT_APP_MUI_X_KEY || '',
  defaultTenant: window._env_.REACT_APP_DEFAULT_TENANT || 'core.local',
  apiHost: window._env_.REACT_APP_BLINK_HOST || 'http://api.blink.local',
  baseHost: window._env_.REACT_APP_BASE_HOST || 'http://blink.local',
  qrHost: window._env_.REACT_APP_BLINK_QR_HOST || 'https://qrurl.dev',
  tenants: csvToArray(window._env_.REACT_APP_TENANTS ?? '') || csvToArray('core.local'),
  version: window._env_.REACT_APP_VERSION || '1.0.0',
  staticHost: window._env_.REACT_APP_STATIC_HOST || 'https://static.dev.blink.app',
  pusherAppID: window._env_.REACT_APP_PUSHER_APP_ID,
  pusherKey: window._env_.REACT_APP_PUSHER_KEY,
  pusherSecret: window._env_.REACT_APP_PUSHER_SECRET,
  pusherCluster: window._env_.REACT_APP_PUSHER_CLUSTER,
  auth0Domain: window._env_.REACT_APP_AUTH0_DOMAIN,
  auth0ClientID: window._env_.REACT_APP_AUTH0_CLIENT_ID,
  echoHost: window._env_.REACT_APP_ECHO_HOST,
};

export default config;
